@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content;}
  &::-moz-placeholder          {@content;}
  &:-moz-placeholder           {@content;}
  &:-ms-input-placeholder      {@content;}
}

@mixin stretched($offset: 0, $position: absolute){
    position:$position;
    left:$offset;
    right:$offset;
    top:$offset;
    bottom:$offset;
}

@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}