.region-cart{
  @include stretched;
  .block {
    position: absolute;
    right: 20px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: column;
    height: 73px;
    justify-content: center;
    z-index: 10;
    line-height: 22px;
    .quantity {
      display: block;
      padding-left: 40px;
      z-index: 1;
      position: relative;
      height: 44px;
      background: url(/sites/all/modules/commerce/dc_ajax_add_cart/images/shopping-cart.png) no-repeat 0 50%;
    }

    .cart-product-total {
      position:relative;
      z-index: 0;
      padding-left: 40px;
      margin-top: -22px;
    }

    .cart-product-quantity,
    .cart-product-total {
      float:none;
      padding-right:0;
      margin-right: 0;
    }

    @include media('<desktop-l') {
      right: 70px;
    }
    @include media('<phone') {
      .cart-product-quantity span.text,
      .cart-product-total {
        display: none;
      }
      .cart-product-quantity span.value{
        width: 20px;
        height: 20px;
        background: rgba(255, 255, 255, 0.8);
        line-height: 20px;
        border-radius: 100%;
        font-size: 11px;
        color: #000;
        text-align: center;
        margin-left: -18px;
        position: absolute;
      }
      right: 70px;
    }
  }

}
.front .region-cart .block {
  color: rgba(65, 64, 66, 0.6);
  a {
    color: rgba(65, 64, 66, 0.6);
  }
}

.commerce-add-to-cart {
  .form-item-quantity {
    float:left;
    padding-right: 10px;
    input {
      height: 41px;
      width: 41px;
      box-sizing: border-box;
      text-align: center;
    }
    label {
      display: none;
    }
  }
  .form-submit {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
  }
  .form-type-select {
    margin-bottom: 20px;
    select {
      min-width: 200px;
      height: 27px;
      border: 1px solid #a58f64;
    }
  }
}

.node-product {
  .field-type-commerce-price {
    padding: 10px 0;
    font-size: 22px;
  }
  .field-name-field-product{
    display: inline-block;
    width: 124px;
  }
}

.add-cart-message-wrapper {
    background-color: #fff;
    border-radius: 0;
    border:0;
    left: 50%;
    top: 50%;
    max-width: 480px;
    transform: translate(-50%, -50%);
    width: calc(80% - 40px);
    padding: 20px;
    position: fixed;
    box-shadow: 0 2px 5px #333;
    right: 0;
    z-index: 10000;
}

.add-to-cart-close {
  margin-top: -33px;
  margin-right: -33px;
}

.view-commerce-cart-form {
  table {
    width: 100%;
  }
  td, th {
    text-align: center;
    vertical-align: middle;
  }
  img {
    margin-bottom: 0 !important;
  }
  th {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  td {
    height: 100px;
  }
  tr {
    border-bottom: 1px solid rgba($mainColor, 0.5);
  }
  .views-field-field-images {
    width: 100px;
    text-align: center;
  }
  .views-field-line-item-title {
    text-align: left;
    padding-left: 10px;
  }
  .views-field-edit-delete {
    width: 60px;
  }
  .views-field-edit-quantity {
    input {
      height: 40px;
      font-size: 15px;
      text-align: center;
      @include media('<desktop') {
        height: 30px;
        width: 40px;
      }
    }
  }
  .views-field-commerce-total {
    @include media('>=desktop'){
      font-size: 18px;
    }
  }
  .delete-line-item {
    width: 30px;
    height: 30px;
    background: url(../img/remove.svg) 50% 50% no-repeat;
    background-size: 20px 20px;
    opacity: 0.8;
    transition: all 0.3s;
    &:hover{
      opacity: 1;
    }
  }
  .form-actions {
    text-align: left;
    margin: 30px 0;
    @include clearfix;
    input, a {

      padding-top: 0;
      padding-bottom: 0;
      margin: 0  20px 20px 0;
      display: block;
      float:left;
      text-decoration: none;
      line-height: 41px;
      @include media('<tablet') {
        line-height: 30px;
        padding: 0 30px;
      }
    }

    .ajax-progress {
      position: absolute;
      margin-left: -17px;
    }

    #edit-submit--2, #edit-download, #order-print {
    }
  }
  @include media('<=phone') {
    th {
      display: none;
    }
    td {
      display: block;
      text-align: left !important;
      height: auto;
      padding: 5px 0;
      float:left;
      width: auto !important;
      padding-right: 10px;
      padding-left: 0;
      line-height: 30px;
      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 15px;
      }
    }
    .views-field-commerce-unit-price{
      clear:left;
    }
  }
}

.line-item-summary {
  text-align: left;
  margin: 30px 0;
  .line-item-total-raw {
    font-size: 24px;
    padding-left: 10px;
  }
}

#commerce-pdf-send-by-enail-form {
  label {
    display: block;
    font-size: 14px;
    padding-bottom: 5px;
  }
  .form-item {
    margin-bottom: 20px;
  }
  .form-text {
    max-width: 280px;
    border: 1px solid #a58f64;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
  }
}
@include media('<tablet') {
  .form-submit {
    padding: 5px 30px;
  }
}

.commerce-price-formatted-components {
  width: auto !important;
  margin-left: 0 !important;
  margin-top: 20px;
  td {
    height: auto;
    text-align: left;
    padding: 10px 0;
    &.component-title {
      padding-right: 30px;
    }
  }
  .component-type-commerce-price-formatted-amount .component-total{
    font-size: 24px;
  }
}