#commerce-checkout-form-checkout {
  margin-top: 30px;

  p {
    margin-bottom: 0 !important;
  }

  .field-type-markup {
    font-size: 18px;
    margin: 20px 0;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    & > fieldset {
      width: 33.33333%;
      box-sizing: border-box;
      border: 1px solid rgba(#414042, 0.2);
      padding: 20px;
      & > legend {
        font-size: 18px;
        padding: 0 10px;
      }
    }
    .checkout-buttons {
      width: 100%;
      border: 0;
      padding: 30px 0 0 0;
      margin-bottom: 0;
    }
    & > .messages {
      width: 100%;
    }
    .commerce_fieldgroup_pane__group_event {
      border: {
        left: 0;
        right: 0;
      }
    }
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    &.option {
      margin-bottom: 0;
      display: inline;
    }
  }
    
  .form-item{
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
    .form-item {
      margin: 0;
    }
  }
  .form-textarea {
    border: 1px solid #a58f64;
  }
  .form-text {
    border: 1px solid #a58f64;
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
  }

  .form-type-select select {
    width: 100%;
    height: 27px;
    border: 1px solid #a58f64;
  }

  .field-type-datetime{
    legend {
      font-size: 14px;
      padding-bottom: 5px;
    }
    .description {
      display: none;
    }
    .date-padding {
      padding: 0;
      float: none;
      width: 100%;
    }
    .date-no-float{
      width: 100%;
      .form-item {
        width: 100%;
        float: none;
      }
    }
    input {
      width: 100% !important;
    }
  }

  .form-radios, .form-checkboxes {
    .form-item {
      margin: 10px 0;
      line-height: 18px;
      display: flex;
      align-items: center;
      input {
        margin-right: 5px;
      }
    }
  }
  .description {
    font-size: 14px;
    color: #999;
  }

  .checkout-buttons {
    .checkout-cancel {
      padding: 0;
      color: #a58f64;
    }
  }

  @include media('<768px') {
    & > div {
      flex-direction: column;
      & > fieldset {
        flex: 1;
        width: auto;
        border: 1px solid rgba(#414042, 0.2);
        margin-bottom: 20px;
      }
      .checkout-buttons {
        padding-top: 0;
      }
      .commerce_fieldgroup_pane__group_event {
        border: {
          left: 1px solid rgba(#414042, 0.2);
          right: 1px solid rgba(#414042, 0.2);
        }
      }
    }
  }

  .error {
    border-color: #ed541d !important;
  }

  .checkout-processing {
    display: none !important;
  }
}