.not-front #menu .fixwidth > ul {
  padding-left: 280px;
}
.front .sf-js-enabled {
  right: auto;
}

.slicknav_menu {
  padding: 17px 14px 17px;
}
.front {
  .fixwidth {
    max-width: 1100px; 
  }
  #menu > .fixwidth > ul {
    padding-left: 304px;
  }
}
.form-submit {
  -webkit-appearance: none;
  border-radius: 0;
}
@include media('<375px') {
  .front #logo img, #logo img {
    width: 180px;
    transform: translateY(9px);
  }
}